.container {
    flex: 9;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.btns {
    position: absolute;
    display: flex;
    gap: 10px;
    right: 30px;
    top: 82px;
}

.header {
    border-bottom: 1px solid black;
    display: flex;
    flex: 0.5;
}

.header .cols:last-child {
    padding-right: 15px;
}

.list {
    flex: 9.5;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 675px;
}

.empty {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.rows {
    flex: 1;
    border-bottom: 1px solid #ddd;
    max-height: 200px;
    min-height: 200px;
    display: flex;
}

.rows:hover {
    cursor: pointer;
    border: 2px solid var(--hows-point-color-lightest);
}

.rows.checked {
    background-color: var(--hows-point-color-lightest);
}

.cols {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.cols:first-child {
    flex: 1;
}

.cols:nth-child(2) {
    flex: 1;
}

.cols:nth-child(3) {
    flex: 6;
}

.cols:nth-child(3) img {
    width: 35%;
}

.cols:last-child {
    flex: 2;
}

.modalTitle {
    text-align: center;
}

.imgLabel {
    height: 240px;
    border: 1px solid black;
    margin: 10px 0;
    width: 510px;
}

.imgLabel label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileInput {
    display: none;
}

.preview {
    width: 100%;
    height: 100%;
}

.selectInfo {
    display: flex;
    margin: 25px 0;
    gap: 75px;
}

.selectInfo input {
    width: 85%;
    padding: 5px;
    font-size: 16px;
    background-color: var(--hows-white);
    border-radius: 8px;
    border: 2px solid var(--hows-point-color);
    color: var(--hows-point-color-darker);
    font-weight: 500;
}

.selectInfo input[type="date"] {
    width: 120%;
}

.choiceDate,
.choiceOrder {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: min-content;
    min-width: 80px;
}

.choiceDate div {
    padding-left: 5px;
}

.choiceOrder input {
    padding: 6px;
}

.modalBtns {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.eventList {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.events {
    display: flex;
    gap: 10px;
    font-size: 18px;
}

.events:hover {
    cursor: pointer;
}

.events .eventTitle span {
    display: inline-block;
    width: 200px;
    min-width: 200px;
    white-space: nowrap;
    /* 텍스트를 한 줄로 처리 */
    overflow: hidden;
    /* 넘치는 부분을 숨김 */
    text-overflow: ellipsis;
    /* 넘치는 텍스트를 '...'으로 표시 */
}

.subModalBtns {
    padding-top: 15px;
    display: flex;
    gap: 10px;
    justify-content: center;
}