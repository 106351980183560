.container{
    padding-bottom: 20px;
    max-width: 1300px;
    padding: 50px 0;
    width: 100%;
    margin: 0 auto;
    /* min-width: 1000px; */
}
/* 카테고리 제목 */
.title {
    font-size: 20px;
    height: 50px;
    padding: 20px 10px;
    font-weight: bold;
}
/* 카테고리 아이템 */
.item{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
}
.categoryItem {
    position: relative;
    height: 150px;
    width: 150px;
    cursor: pointer;
    /* border: 1px solid #c8c6c66d; */
}

.categoryItem > div:first-child {
    height: 100%; 
    width: 100%; 
    z-index: 1;
    border-radius: 8px;
    position: absolute;
}

/* 카테고리명 */
.categoryItem > div:last-child {
    position: absolute; 
    z-index: 2;
    border-radius: 8px;
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    font-size: 20px;
    background-color: #00000051;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    text-shadow: 1px 1px 1px #9793935a;
}

.categoryItem:hover > div:last-child {
    opacity: 0;   /* 투명하게 만듦 */
    visibility: hidden;  /* 요소를 완전히 숨김 */
}

/* 이미지 */
.categoryItem > div:first-child .image {
    object-fit: contain; 
    width: 100%; 
    height: 100%;
}

/* 반응형 레이아웃 */
@media (max-width: 1200px) {
    .categoryItem > div:last-child {
        font-size: 18px;
    }
    .categoryItem {
        height: 130px;
        width: 130px;
    }
}

@media (max-width: 900px) {
    .categoryItem > div:last-child {
        font-size: 14px;
    }
    /* 화면이 작아지면 아이템 크기를 줄이고 3개씩 표시 */
    .categoryItem {
        width: calc(33.33% - 20px);
        height: 130px;
        margin: 5px;
    }
    .container {
        padding: 10px 0px;
    }
}

@media (max-width: 600px) {
    .categoryItem > div:last-child {
        font-size: 12px;
    }
    .categoryItem {
        width: calc(100% - 20px);
        height: 100px;
        margin: 5px 0;
    }
    .container {
        padding: 20px 10px;
    }
}