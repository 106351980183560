.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.container .loginBox {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 500px;
  text-align: center;
}
.container .loginBox .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 40px 10px;
  cursor: pointer;
}

.container .loginBox .logo .logoBox {
  width: 260px;
  overflow: hidden;
  object-fit: cover;
}

.container .loginBox .logo .logoBox > img {
  width: 100%;
}

.container .loginBox > input {
  height: 35px;
  border: 1px solid var(--hows-gray-200);
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 8px;
}
.container .loginBox > input:focus {
  /* 테두리 색상 */
  outline: 1px solid var(--hows-gray-200);
}

.container .loginBox .loginTool {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.container .loginBox .loginTool .tool {
  margin: 0 10px;
}
.container .loginBox .loginTool .tool > input {
  margin-right: 5px;
}

.container .loginBox > button,
.signUpBtn {
  height: 50px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition-duration: 0.4s;
}

.container .loginBox .loginBtn {
  margin-top: 10px;
  background-color: var(--hows-point-color-dark);
  color: var(--hows-white);
}
.container .loginBox .loginBtn:hover {
  background-color: var(--hows-point-color-darker);
}

.container .loginBox .kakaoLoginBtn {
  background-color: #f5d370;
  color: var(--hows-white);
}
.container .loginBox .kakaoLoginBtn:hover {
  background-color: #face4b;
}

.container .loginBox .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 15px 0;
}

.container .loginBox .links .linkBtn {
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin: 0 10px;
  color: var(--hows-point-color-darker);
}

.container .loginBox .signUpBtn {
  margin-top: 10px;
  background-color: var(--hows-gray-200);
  color: var(--hows-white);
}
.container .loginBox .signUpBtn:hover {
  background-color: var(--hows-gray-300);
}
