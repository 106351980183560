.detailBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
}

.titleCont {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--hows-gray-200);
    padding-bottom: 20px;
}

.subTxt {
    display: flex;
    align-items: center;
    gap: 10px;
}

.eventTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--hows-gray-700);
    word-break: keep-all;
    word-wrap: break-word;
}

.eventDate {
    font-size: 16px;
    color: var(--hows-gray-500);
    margin-bottom: 20px;
}

.viewCount {
    font-size: 16px;
    color: var(--hows-gray-500);
    margin-bottom: 20px;
}

.eventContents {
    font-size: 18px;
    margin-bottom: 40px;
    word-break: keep-all;
    word-wrap: break-word;
}

.eventImage {
    display: block;
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
}

.returnButton {
    text-align: center;
}