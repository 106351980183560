.container {
  width: 100%;
}
.container .countContents {
  padding: 5px 0 10px 15px;
  font-size: 20px;
}
.container .countContents > span:nth-child(1) {
  font-weight: 600;
  margin-right: 5px;
}

.visitPost {
  margin-bottom: 50px;
}

.visitPost .input {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 15px;
  background-color: #c0cfb191;
  border-top: 1px solid var(--hows-point-color-darker);
  overflow: hidden;
}
.visitPost .input > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 5px 0 10px;
  border: 1px solid var(--hows-point-color-dark);
}
.visitPost .input .inputText {
  width: 88%;
  height: auto;
  min-height: 45px;
  max-height: 100px;
  overflow-y: auto;
  margin: 0 20px;
  padding: 4px 16px 4px 5px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background-color: #ffffff;
}
.visitPost .input > button {
  width: 60px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  background-color: var(--hows-point-color-dark);
  color: white;
  cursor: pointer;
  transition-duration: 0.4s;
}
.visitPost .input > button:hover {
  background-color: var(--hows-point-color-darker);
}

.visitPost .output {
  display: flex;
  padding: 25px 8px 35px 20px;
  border-bottom: 1px solid var(--hows-point-color-light);
}

.visitPost .output > img {
  width: 80px;
  height: 80px;
  border: 1px solid var(--hows-point-color-darker);
  border-radius: 50%;
  margin: 0 20px 20px 10px;
}

.visitPost .output > div {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.visitPost .output > div .writer_writeDate {
  height: 20px;
  margin-bottom: 20px;
}
.visitPost .output > div .writer_writeDate > span:nth-child(1) {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}
.visitPost .output > div .writer_writeDate > span:nth-child(2) {
  margin-left: 10px;
  color: var(--hows-gray-400);
  font-size: 16px;
}

.visitPost .output .content {
  font-size: 20px;
  padding-left: 5px;
  max-height: 150px;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.visitPost .output > button {
  padding: 0px;
  width: 30px;
  height: 35px;
  font-size: 16px;
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
  color: #959595;
}
