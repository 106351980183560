.container {
    /* border: 1px solid #000; */
    width: 100%;
    height: 70px;
    background-color: white;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.container > .row {
    width: 100%;
    max-width: 1300px;
    padding: 10px 0;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.menu {
    display: flex; 
    height: 100%;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 20px;
}
.menu > li {
    cursor: pointer;
    padding: 0 10px; 
    line-height: 50px;
}

/* 현재 클릭된 메뉴 색상 이벤트 */
.naviMenu:hover {
    color: var(--hows-point-color);
}

.naviMenu.active {
    color: var(--hows-point-color);
}