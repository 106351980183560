.container {
  width: 100%;
  height: 100%;
  /* background-color: aliceblue; */
}

.container > .couponFormTop {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.container > .couponFormTop > .countCoupons > select {
  width: 200px;
}

.container .countCoupons {
  padding: 5px 0 10px 15px;
  font-size: 20px;
}

.container .countCoupons > span:nth-child(1) {
  font-weight: 600;
  margin-right: 5px;
}

.container .couponBox {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 30px;
  border: 1px solid var(--hows-gray-200);
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.container .couponBox:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.container .couponBox .eventName {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.container .couponBox .eventName .discount {
  margin-right: 10px;
}

.container .couponBox .expiredDate {
  font-size: 18px;
}

.container .couponBox .useDate {
  font-size: 18px;
  color: var(--hows-gray-300);
}