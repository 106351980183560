.contailer{
    max-width: 1300px;
    min-width: 1300px;
    padding: 20px 0;
    width: 100%;
    margin: 0 auto;
}
.contailer i {font-size: 24px;}

.contents{
    /* border: 1px solid #000; */
    height: 500px;
    display: flex; justify-content: space-between;
    font-size: 20px;
}

/* 이미지 */
.img {
    overflow: hidden;
    width: 650px;
    height: 500px;
    border-radius: 8px;
}
.img > img {
    width: 100%;height: 100%;
    object-fit: cover;
}
/* content 영역 스타일링 */
.content {
    padding-left: 50px;
    /* padding: 0 20px; 여백 추가 */
    width: 650px;
    height: 500px;
    /* border: 1px solid #e0e0e0;  */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff; 
}

/* 상품 제목 스타일링 */
.product_title {
    border-bottom: 1px solid #e0e0e0; /* 제목 아래에만 테두리 추가 */
    padding-bottom: 10px; /* 아래쪽 여백 추가 */
    display: flex;
    justify-content: space-between;
    align-items: center; /* 제목과 버튼을 수직 중앙 정렬 */
    margin-bottom: 20px; /* 제목과 내용 사이 여백 추가 */
}

/* 좋아요 하트 */
.product_title i {
    font-size: 35px;
    color: var(--hows-point-color);
}

/* 상품 이름 */
.product_title > div:first-child {
    font-weight: bold; 
    font-size: 24px;
    padding-right: 10px;
}

/* 장바구니/바로구매/하트 버튼 */
.product_title > div:last-child {
    display: flex; align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

/* 상품 내용 */
.product_contents {
    flex: 1; /* 내용이 가능한 공간을 차지하도록 설정 */
    padding: 10px 0; /* 위아래 여백 추가 */
    overflow-y: auto; /* 내용이 넘칠 경우 스크롤 추가 */
    display: flex;flex-direction: column;
    font-weight: bold;
}
.product_contents > div {flex: 1;}
.product_contents > div:nth-child(1){font-size: 24px;}
.product_contents > div:nth-child(3){color: var(--hows-gray-500);display: flex;align-items: center;}
.product_contents > div:nth-child(4) span{ font-size: 14px;}

/* 구매하기 */
.buy {
    flex: 1;
    display: flex;
    flex-direction: column;

}

/* 수량 / 가격 / 엑스버튼 / 장바구니 / 바로구매 */
.buy > div:first-child{
    flex: 7;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
}
.buy button {
    border: none; 
    background-color: #f5f5f5; 
    padding: 5px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    margin: 0 10px;
}
.buy > div:first-child > div:first-child {
    height: 25%; 
    display: flex;justify-content: right;align-items: end; 
    margin-right: 15px; 
    cursor: pointer;
}
.buy > div:first-child > div:nth-child(2) {
    height: 25%; 
    display: flex;justify-content: center;align-items: center;
}
.buy > div:first-child > div:last-child {
    height: 50%; 
    display: flex;justify-content: center;align-items: center; 
    font-size: 24px;font-weight: bold;
}

/* 바로구매 */
.buy > div:last-child{
    flex: 3;
    display: flex;justify-content: center;align-items: center;
    border-radius: 8px;
    background-color: #f5f5f5; 
}
.buy > div:last-child > div {
    flex: 1; display: flex; justify-content: center; align-items: center;
    height: 100%;
    /* background-color: var(--hows-point-color); */
    /* color: white;  */
    letter-spacing: 1px; font-weight: bold;
    cursor: pointer;
    transition: all ease 0.1s;
    border: 1px solid white;
    box-sizing: border-box;
}

.buy > div:last-child > div:first-child {border-radius: 8px 0 0 8px;}
.buy > div:last-child > div:last-child {border-radius: 0 8px 8px 0;}
.buy > div:last-child > div:hover {opacity: 0.9;}