.faqCont {
    max-width: 1300px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 10px;
}

.faqItem {
    cursor: pointer;
}

.faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: var(--hows-gray-700);
    border-bottom: 1px solid var(--hows-point-color);
    padding: 20px 0;
}

.faqQuestion.activeQuestion {
    border-bottom: none;
    /* 활성화된 질문의 border-bottom 없앰 */
}

.faqBox {
    display: flex;
    gap: 10px;
    align-items: center;
}

.faqAnswer {
    font-size: 16px;
    padding-left: 25px;
    color: var(--hows-white);
    background-color: var(--hows-point-color);
    border-radius: 8px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: .2s;
    word-break: keep-all;
    word-wrap: break-word;
}

.faqAnswer::before {
    content: 'A';
    font-weight: bold;
    margin-right: 10px;
}

.faqAnswer.active {
    max-height: 500px;
    /* 매우 큰 값으로 설정해 자연스러운 애니메이션을 유지 */
    opacity: 1;
    padding: 20px 0;
    padding-left: 25px;
    /* 애니메이션과 함께 padding도 조정 */
}