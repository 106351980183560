.community {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: calc(100vh - 440px);
}

.writeTit {
    font-size: 24px;
    font-weight: 700;
}

/* Post.module.css */

.writeTit {
    font-size: 24px;
    font-weight: bold;
}

.writeWrap {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    height: calc(100vh - 510px);
}

.imageUploader {
    width: 40%;
    display: flex;
    gap: 20px;
}

.imagePreviewList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;
}

.imagePreview {
    position: relative;
}

.imageBox {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 1px solid var(--hows-point-color);
    border-radius: 8px;
}

.imageBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deleteButton {
    position: absolute;
    top: calc((50px - 20px)/2);
    right: 10px;
    color: var(--hows-point-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    background-color: unset;
    font-size: 20px;
}

.addImageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid var(--hows-point-color);
    cursor: pointer;
    border-radius: 8px;
}

.uploadButton {
    background-color: var(--hows-point-color-darkest);
    color: var(--hows-white);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.dropzone {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--hows-point-color);
    gap: 10px;
    height: 100%;
    font-size: 16px;
    text-align: center;
}

.addImageBtn {
    background: url(../../../../assets/images/plus.png) center no-repeat;
    background-size: 24px;
    width: 100%;
    height: 100%;
}

.addButton {
    font-size: 24px;
    color: var(--hows-point-color-darkest);
}

.thumbnailCont {
    width: 80%;
    border: 2px solid var(--hows-point-color);
    height: 100%;
    border-radius: 8px;
    display: flex;
    font-weight: 600;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow: hidden;
}

.thumbnailBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.thumbnailBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnailControls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.tagIcon {
    position: absolute;
    font-size: 24px;
    color: #00bcd4;
}

.tagMarker {
    background-color: var(--hows-point-color);
    color: var(--hows-white);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.txtBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.selectBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.txtWriteBox {
    border: 2px solid var(--hows-point-color);
    border-radius: 8px;
    height: 100%;
    resize: none;
    padding: 10px;
    font-size: 14px;
    color: var(--hows-gray-700);
    font-weight: 700;
}

.tagModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    padding: 10px;
    max-height: 400px;
}

.tagTitle {
    display: flex;
    justify-content: center;
    color: var(--hows-gray-700);
}

.productList {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.searchResultItem {
    display: flex;
    gap: 10px;
}

.productImg {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 1px solid var(--hows-point-color);
    border-radius: 8px;
}

.productImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.productInfo {
    width: calc(100% - 70px);
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.productInfo span {
    word-wrap: break-word;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: calc(100% - 60px)
}