.imageSwiperContainer {
    position: relative;
    width: 100%;
}

.imageSwiper {
    width: 100%;
    aspect-ratio: 3 / 4;
    /* 3:4 비율 설정 */
}

:global(.swiper-slide) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageBox {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.slideImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* 커스텀 네비게이션 버튼 스타일 */
.swiperButtonPrev,
.swiperButtonNext {
    width: 40px;
    height: 40px;
    background-color: var(--hows-gray);
    border-radius: 50%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: 700;
    color: var(--hows-point-color-darkest);
}

.swiperButtonPrev {
    left: 0px;
    /* 왼쪽 버튼 위치 */
}

.swiperButtonNext {
    right: 0px;
    /* 오른쪽 버튼 위치 */
}

/* 비활성화 상태의 네비게이션 버튼 숨기기 */
:global(.swiper-button-disabled) {
    display: none !important;
}

.productTag {
    background-color: var(--hows-point-color);
    color: var(--hows-white);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.modalContent {
    width: 400px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.productImgBox {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
}

.productThumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product_title {
    font-size: 24px;
    font-weight: 600;
}

.product_txt {
    word-break: keep-all;
    word-wrap: break-word;
    font-size: 14px;
    color: var(--hows-gray-500);
}

.product_price {
    font-size: 16px;
    font-weight: 700;

}