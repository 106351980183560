.commentCont {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
}

.commentInfo {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

.imgBox {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.imgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.commentBox {
    display: flex;
    width: calc(100% - 50px);
    flex-direction: column;
    gap: 10px;
    color: var(--hows-gray-700);
    margin-left: 50px;
}

.commentName {
    font-size: 18px;
    font-weight: 700;
}

.commentTxt {
    word-break: keep-all;
    word-wrap: break-word;
    font-size: 16px;
    white-space: pre-wrap;
}

.commentTxt textarea {
    resize: none;
    width: 100%;
    padding: 5px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    overflow-y: auto;
    letter-spacing: .7px;
    color: var(--hows-gray-700);
    border: 1px solid var(--hows-gray-600);
    word-wrap: break-word;
    white-space: pre-wrap;
}

.btnBox {
    display: flex;
    justify-content: space-between;
}

.btnLeft {
    display: flex;
    gap: 10px;
    align-items: center;
}

.btnLeft div {
    height: 24px;
    display: flex;
    align-items: center;
}

.btnLeft i {
    font-weight: 500;
}

.reportComment {
    display: flex;
    align-items: center;
}

.btnLike {
    display: flex;
    align-items: center;
    gap: 5px;
}

.likeCount {
    display: flex;
    align-items: center;
    height: 24px;
}

.editInput {
    width: 100%;
    resize: none;
    padding: 5px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    overflow-y: auto;
    letter-spacing: .7px;
    color: var(--hows-gray-700);
}

/* 답글 UI */
.replyBtnBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.replyLeft {
    display: flex;
    gap: 10px;
    align-items: center;
}

.replyRight {
    display: flex;
    gap: 10px;
}

.replyInputContainer {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.replyTextarea {
    width: calc(100% - 50px);
    padding: 5px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    overflow-y: auto;
    letter-spacing: .7px;
    color: var(--hows-gray-700);
    border: 1px solid var(--hows-gray-600);
    word-wrap: break-word;
    white-space: pre-wrap;
    resize: none;
}

.toggleReplyBtn {
    display: flex;
    justify-content: flex-start;
    color: var(--hows-point-color);
}