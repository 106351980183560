.subCategory {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--hows-gray-200);
    background-color: white;
    margin-bottom: 30px;
}

.button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin: 0 20px;
    color: var(--hows-gray-700);
    cursor: pointer;
    padding: 30px;
    position: relative;
}

.button:hover {
    color: var(--hows-point-color);
}

.active {
    color: var(--hows-point-color);
}

.active::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: var(--hows-point-color);
}