.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    font-size: 32px;
    font-weight: 900;
    margin: 60px 0;
}

.cartInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1240px;
    height: 100px;
    border-top: 1px solid var(--hows-gray-300);
    border-bottom: 1px solid var(--hows-gray-300);
    padding: 20px 10px;
}

.cartInfo > .leftBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cartInfo > .leftBox > p {
    font-size: 18px;
}

.cartInfo > .leftBox > button {
    width: 200px;
    height: 40px;
    background-color: var(--hows-point-color);
    border-radius: 5px;
    color: var(--hows-white);
    font-size: 16px;
    font-weight: 900;
    border: none;

}

.cartInfo > .rightBox {
    display: flex;
    gap: 12px;
}

.cartInfo > .rightBox > div > p {
    font-size: 16px;
    margin: 12px 0;
}

.cartInfo > .rightBox > div > p:nth-child(3) {
    font-size: 20px;
    font-weight: 800;
    margin-top: 15px;
}

.cartInfo > .rightBox > div {
    text-align: right;
}

.itemForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1300px;
    height: 100%;
}

.itemForm > .btnBox {
    width: 95%;
    display: flex;
    height: 30px;
    margin: 15px 0 5px 0;
}

.itemForm > .btnBox > input {
    transform: scale(1.3);
}

.itemForm > .btnBox > button {
    margin-left: 15px;
    width: 80px;
    height: 30px;
    padding: 4px;
    background-color: var(--hows-point-color);
    border-radius: 5px;
    border: none;
    color: var(--hows-white);
    font-weight: 700;
}

.itemForm > .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 30px;
}

.itemForm > .items > .item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 95%;
    height: 150px;
    border: 1px solid var(--hows-gray-400);
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px;
}

.itemForm > .items > .item > input {
    margin: 0 5px;
    transform: scale(1.3);
}

.itemForm > .items > .item > .itemImage {
    width: 150px;
    height: 90%;
}

.itemForm > .items > .item > .itemImage > img {
    width: 100%;
    height: 100%;
}

.itemForm > .items > .item > .itemInfo {
    width: 60%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
}

.itemForm > .items > .item > .itemInfo > p {
    font-size: 20px;
    font-weight: 700;
}

.itemForm > .items > .item > .itemInfo > p:hover {
    cursor: pointer;
}


.itemForm > .items > .item > .itemInfo .itemCount {
    display: flex;
    gap: 20px;
    font-size: 16px;
}

.itemForm > .items > .item > .itemInfo .itemCount > button {
    background-color: transparent;
    border: none;
    font-size: 20px;
}

.itemForm > .items > .item > .itemPrice {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-left: 1px solid var(--hows-gray-400);
}

.itemForm > .items > .item > .itemPrice > p {
    font-size: 18px;
    font-weight: 900;
    padding: 10px 0;
}

.itemForm > .items > .item > .itemPrice > button {
    width: 90%;
    height: 35px;
    background-color: var(--hows-point-color);
    border-radius: 5px;
    color: var(--hows-white);
    border: none;
    font-size: 16px;
    font-weight: 900;
}

.noneItem {
    width: 80%;
    border-top: 1px solid var(--hows-gray-300);
    padding: 20px;
}