.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .spanBox {
  padding: 50px;
  height: 80px;
}

.container .text {
  font-size: 24px;
  font-weight: 600;
  color: var(--hows-point-color);
}
.container .text2 {
  font-size: 20px;
  font-weight: 600;
}
