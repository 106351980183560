.memberContainer {
    flex: 9 1;
}

.headerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

/* ㄱㄴㄷㄹ.....ㅎ */
.filter {
    font-size: 16px;
    color: #555;
    display: flex;
    gap: 5px;
}

.filterItem {
    cursor: pointer;
    padding: 5px 10px;
}

.selected {
    border-bottom: 2px solid #555;
    font-weight: bold;
    color: var(--hows-black);
}

/* 검색창! */
.searchSection {
    display: flex;
    justify-content: flex-end;
}

.searchSection select,
.searchSection input {
    padding: 5px;
    margin-right: 5px;
}

.searchSection button {
    padding: 5px 10px;
}

/* 조회 하는 영역 */
.memberlist {
    font-size: 17px;
    height: 85%;
}

.memberHeader {
    display: flex;
    background-color: #555;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #ddd;
    color: var(--hows-white);
    height: 35px;
    justify-content: center;
    align-items: center;
}

.headerItem {
    flex: 1;
    text-align: center;
}

.memberRow {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    height: 35px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.memberItem {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deletebtn {
    padding: 10px 20px;
    background-color: #555;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

/* 회원 상세조회 모달*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}

.modalContent {
    width: 55%;
    height: 60%;
    background-color: var(--hows-white);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid var(--hows-point-color);
}

.modalContent h2{
    display: flex;
}

.profile {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.profile img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 3px solid var(--hows-point-color);
}

.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    font-size: 18px;
}

.infoItem {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.infoItem label {
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    position: relative;
    left: 23%;
}

.infoItem input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 auto;
}

.empty{
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 40%;
}

/* 페이지네비 */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 88%;
    left: 51%;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination i {
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}







