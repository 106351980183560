.headerWrap {
  border-bottom: 1px solid var(--hows-gray-300);
}

.headerSpacer {
  height: 82px;
  /* 헤더 높이에 맞게 설정하세요 */
  width: 100%;
}

.headerCont {
  width: 100%;
  display: flex;
  gap: 20px;
  color: var(--hows-gray-900);
  position: relative;
  z-index: 998;

  /* 애니메이션을 위한 트랜지션 */
  transition: top 0.1s;
}

.headerCont.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 82px;
  background-color: var(--hows-white);
  /* 배경색이 필요하다면 추가 */
  z-index: 1000;
  border-bottom: 1px solid var(--hows-gray-300);
  /* 패딩을 추가해도 좋습니다 */
}

.mainNavi {
  max-width: 1300px;
  display: flex;
  padding: 20px 0;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.menuBox {
  display: flex;
  align-items: center;
  width: 100%;
}

.mainLogo {
  margin-right: 30px;
}

.mainLogo a {
  display: flex;
  object-fit: cover;
  max-width: 100px;
}

.mainLogo img {
  width: 100%;
  height: 100%;
}

.naviMenuList {
  display: flex;
  gap: 20px;
}

.naviMenu {
  font-size: 24px;
  font-weight: 700;
  color: var(--hows-gray-800);
}

.naviMenu:hover {
  color: var(--hows-point-color);
}

.naviMenu.active {
  color: var(--hows-point-color);
}

.naviInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.infoIcon {
  font-size: 24px;
}

.infoIcon a:hover i {
  color: var(--hows-point-color);
}

/* 프로필 사진 */
.infoUser {
  width: 40px;
  height: 40px;
  border: 1px solid var(--hows-point-color);
  border-radius: 100%;
  display: flex;
  /* overflow: hidden; */
  cursor: pointer;
  position: relative;
}

.infoUser .profileImg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.profileImg img {
  width: 100%;
  height: 100%;
}

.profileMenu {
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: var(--hows-point-color-lightest);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 0;
  z-index: 1000;
  width: 120px;
}

.profileMenuItem {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: var(--hows-point-color-darker);
}

.profileMenuItem:hover {
  font-weight: 600;
  color: var(--hows-black);
}


.headerWrap {
  border-bottom: 1px solid var(--hows-gray-300);
}

/* 기본적으로 햄버거 메뉴 숨김 */
.hamburgerMenu {
  display: none;
}

/* 모바일 및 태블릿에서 햄버거 메뉴 */
@media screen and (max-width: 768px) {
  .naviMenuList {
    display: none;
    /* 메뉴 숨김 */
  }

  .headerCont.fixed {
    height: 64px;
  }

  .mainLogo {
    margin: 0;
    position: absolute;
    left: calc((100% - 40px) / 2);
  }

  .hamburgerMenu {
    display: block;
    /* 햄버거 메뉴 보이기 */
    cursor: pointer;
    font-size: 30px;
  }

  .mainNavi {
    padding: 10px 0;
  }

  .headerSpacer {
    height: 64px;
  }

  .hamburgerMenu.active+.naviMenuList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    /* 헤더 아래에 메뉴 보이기 */
    right: 0;
    background-color: var(--hows-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hows-white);
    /* 반투명한 배경 */
    z-index: 1500;
    /* 가장 앞에 표시되도록 설정 */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuContent {
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    top: 20px;
  }

  .menuList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  .menuList li {
    font-size: 24px;
    font-weight: bold;
  }

  /* 닫기 버튼 스타일 */
  .closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }

  .hamburgerLogo {
    width: 30px;
    height: 34px;
    overflow: hidden;
    object-fit: cover;
    margin: auto;
    margin-bottom: 30px;
  }

  .hamburgerLogo img {
    width: 100%;
    height: 100;
  }
}