.contentWrap {
    display: flex;
}

.contentWrap>div {
    width: 100% !important;
}

.contentCont {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin: 25px 0;
}

.contentCon {
    width: calc((100% / 4) - 25px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 80px;
}

.contentTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profileImg {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border: 1px solid var(--hows-point-color);
    border-radius: 50%;
}

.profileImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileNickname {
    font-size: 16px;
    font-weight: 700;
}

.mainContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mainContentImg {
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
}

.mainContentImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiperBox {
    height: 100%;
}

.mainContentTxt {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnLeft {
    display: flex;
    align-items: center;
    gap: 10px;
}

.likeBtn {
    display: flex;
    gap: 5px;
    font-size: 16px;
}

.likeBtn i {
    font-size: 24px;
}

.comment {
    display: flex;
    gap: 5px;
    font-size: 16px;
}

.comment i {
    font-size: 24px;
}

.bookMark i {
    font-size: 24px;
}

.contentBox {
    font-size: 16px;
    font-weight: 500;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
}

h4 {
    text-align: center;
}

/* 1100px 이하일 때 3개 */
@media screen and (max-width: 1100px) {
    .contentCon {
        width: calc((100% / 3) - 20px);
    }
}

/* 태블릿 크기(768px) 이하일 때 2개 */
@media screen and (max-width: 768px) {
    .contentCon {
        width: calc((100% / 2) - 15px);
    }
}

/* 모바일 크기(576px) 이하일 때 1개 */
@media screen and (max-width: 576px) {
    .contentCon {
        margin: auto;
        width: 100%;
    }
}