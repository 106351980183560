.container {
    flex: 9;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 10px;
    gap: 10px;
}

.btns {
    position: absolute;
    display: flex;
    gap: 10px;
    right: 30px;
    top: 82px;
}

.category {
    display: flex;
    gap: 10px;
    font-size: 18px;
    padding-left: 10px;
}

.category span {
    cursor: pointer;
}

.category span.active {
    text-decoration: underline;
    color: var(--hows-point-color-darkest);
}

.table {
    border: 1px solid var(--hows-point-color-darker);
    height: 100%;
}

.header {
    display: flex;
    height: 7%;
    border-bottom: 1px solid var(--hows-point-color-darker);
}

.rows {
    display: flex;
    min-height: 60px;
    border-bottom: 1px solid #ddd;
}

.rows:hover {
    cursor: pointer;
    border: 2px solid var(--hows-point-color-lightest);
}

.cols {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cols:first-child {
    flex: 0.5;
}

.cols:nth-child(2) {
    flex: 2;
}

.cols:nth-child(3) {
    flex: 3.5;
}

.cols:nth-child(4),
.cols:nth-child(5) {
    flex: 1.5;
}

.cols:last-child {
    flex: 1;
}

.listBox {
    height: 93%;
    max-height: 620px;
    overflow-y: auto;
}

.empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.modalTitle {
    color: var(--hows-point-color-darkest);
}

.dateInfo,
.orderInfo,
.memberInfo {
    display: flex;
    gap: 30px;
    padding: 13px;
    font-size: 18px;
}

.addressInfo {
    padding: 13px;
    font-size: 18px;
}

.memberInfo .member {
    width: 70%;
}

.subTitle {
    font-size: 20px;
    padding: 5px 0;
    color: var(--hows-point-color-darkest);
    font-weight: 600;
}