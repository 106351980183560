/* 검색창! */
.searchSection {
  display: flex;
  justify-content: flex-end;
}

.searchSection select,
.searchSection input {
  padding: 5px;
  margin-right: 5px;
}

.searchSection button {
  padding: 5px 10px;
}

/* 조회 하는 영역 */
.shippinglist {
  font-size: 17px;
  height: 85%;
}

.shippingHeader {
  display: flex;
  background-color: var(--hows-point-color-dark);
  padding: 10px;
  font-weight: bold;
  border: 1px solid var(--hows-gray-100);
  color: var(--hows-white);
  height: 35px;
  justify-content: center;
  align-items: center;
}

.headerItem {
  flex: 1;
  text-align: center;
}

.shippingRow {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--hows-gray-100);
  height: 35px;
}

.shippingItem {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shippingItem > p:hover {
  cursor: pointer;
}

.modalBox {
  width: 500px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.modalBox > .orderDetail {
  width: 95%;
}

.modalBox > .orderDetail > p {
  margin: 10px 5px;
  font-size: 18px;
}

.modalBox > .orderList {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  border: 1px solid var(--hows-point-color);
  border-radius: 10px;
}

.modalBox > .orderList > .orderProduct {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  height: 100px;
  border-bottom: 1px solid var(--hows-gray-400);
  margin: 5px 0;
  padding: 5px;
}

.modalBox > .orderList > .orderProduct > .image {
  width: 100px;
  height: 90px;
}

.modalBox > .orderList > .orderProduct > .image > img {
  width: 100%;
  height: 100%;
}

.modalBox > .orderList > .orderProduct > .info {
  width: 75%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
}

.modalBox > .orderList > .orderProduct > .info > p {
  font-size: 16px;
  cursor: pointer;
}

.modalBox > .orderList > .orderProduct > .info > .count {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.cancelBtn {
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--hows-point-color);
  color: var(--hows-white);
  font-weight: 700;
}

.cancelReason {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cancelReason > textarea {
  width: 290px;
  height: 150px;
  padding: 5px;
  margin-bottom: 10px;
}

.cancelReason > button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--hows-point-color);
  color: var(--hows-white);
  font-weight: 700;
}