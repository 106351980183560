.container{
    width: 100%; 
    margin-top: 5%;
}

.menu {
    background-color: #f5f5f5;
    border-radius: 8px;
    height: 50px; 
}
.menu > button {
    height: 50px; 
    width: 33.333%;
    border: none;
    /* border-radius: 5px;  */
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 20px;
    border: 1px solid white; 
    transition: all 0.3s ease;
    color: white;
}
.menu > button {
    background-color: var(--hows-point-color);
}
.menu > button:hover {
    opacity: 0.8;
}
.menu > button:first-child {
    border-radius: 8px 0 0 8px;
}
.menu > button:last-child {
    border-radius: 0 8px 8px 0;
}

/* ===== 정보 ===== */
/* ===== 상품정보 ===== */
.info {
    /* margin-top: 5%; */
}
.info img{
    object-fit: cover;
    width: 100%; height: 100%;
    padding-left: 115px;
    margin: 5% 0;
    box-sizing: border-box;
}