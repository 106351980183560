.container{
    max-width: 1300px;
    padding: 50px 0;
    width: 100%;
    margin: 0 auto;
}
.contents {
    display: flex; flex-wrap: wrap;
    padding: 10px 0px;
    justify-content: space-between;
}

/* 상품 */
.titleItem {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 10px;
}
.item {
    width: calc(25% - 20px); /* 4개씩 배치되도록 각각의 너비를 25%로 설정하고 여백을 고려해 20px을 뺍니다 */
    height: 500px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.item:hover .img > img {
    transform: scale(1.1);
    opacity: 0.8;
}

/* 상품 이미지 */
.img {
    height: 400px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    transition: all 0.3s ease;
}
.img > img {
    object-fit: cover; 
    width: 100%;height: 100%; 
    border-radius: 8px 8px 0 0;
    transition: all 0.3s ease;
}

/* 상품 설명 */
.title {
    border:1px solid var(--hows-gray-200);
    border-radius: 0 0 8px 8px;
    height: 100px;
}

/* 상품 설명 - 상품명 */
.title > div{
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; text-align: center;
}

/* 상품 설명 - 상품 가격 */
.title > div:last-child{
    /* border:1px solid #000; */
    font-weight: bold;
}

/* 반응형 레이아웃 */
@media (max-width: 1200px) {
    .item {
        width: calc(33.33% - 20px); /* 중간 화면에서는 3개씩 배치 */
    }
}

@media (max-width: 900px) {
    .item {
        width: calc(50% - 20px); /* 작은 화면에서는 2개씩 배치 */
    }
}

@media (max-width: 600px) {
    .item {
        width: calc(100% - 20px); /* 매우 작은 화면에서는 1개씩 배치 */
    }
}