/* 전체 컨테이너 */
.container {
    padding: 60px 40px;
    letter-spacing: 0.5px;
    border: 1px solid #00000029;
    border-radius: 8px;
    color: #333;
    font-family: 'Noto Sans', sans-serif;
}

/* 제목 스타일 */
.container h3, .container h4 {
    margin: 20px 0;
    padding: 10px 0;
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid var(--hows-point-color);
}

/* 개별 섹션 스타일 */
.container > div {
    margin-bottom: 80px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 개별 항목 라인 */
.line {
    border-bottom: 1px solid var(--hows-point-color);
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* 배송, 교환/환불 정보의 텍스트 */
.line div:first-child {
    font-size: 18px;
    font-weight: 600;
}

.line div:last-child {
    font-size: 16px;
    color: #555555;
    text-align: right;
}

/* 강조된 텍스트 */
.container span {
    font-size: 14px;
    color: #ff6f61;
    font-weight: bold;
}

/* 반품/교환 안내 */
.container ol li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

/* 반품/교환 안내 텍스트 스타일 */
.container > div > p {
    color: #666666;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

/* 반품/교환 사유 텍스트 박스 */
.container h4 {
    border: 3px solid var(--hows-point-color);
    background-color: var(--hows-point-color);
    border-radius: 10px;
    padding: 15px;
    color: white;
}

/* 카드 스타일 (사업자 정보) */
.card {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    max-width: 800px;
    margin: 30px auto;
}

/* 카드 내부 라인 */
.card_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px 0;
}

/* 카드 라벨과 값 */
.label {
    font-weight: 600;
    color: #333;
    width: 40%;
}

.value {
    color: #777;
    width: 60%;
    text-align: right;
}

/* 마지막 항목의 경계선 제거 */
.line:last-child {
    border-bottom: none;
}
