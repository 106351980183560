.container {
    flex: 9;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
    gap: 10px;
}

.btns {
    position: absolute;
    display: flex;
    gap: 10px;
    right: 30px;
    top: 82px;
}

.imgs {
    flex: 1.5;
    display: flex;
    gap: 30px;
}

.addBtn {
    flex: 1;
    max-width: 115px;
    min-width: 115px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
}

.addBtn input {
    display: none;
}

.addBtn label {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

.imgNum {
    flex: 1;
    text-align: center;
}

.addedImg {
    flex: 1;
    max-width: 115px;
    min-width: 105px;
    max-height: 115px;
    border: 1px solid black;
    position: relative;
}

.addedImg img {
    width: 100%;
    height: 100%;
}

.delBtn {
    border: unset;
    background-color: #fff;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
}

.selectBtn {
    border: none;
    background-color: unset;
    position: absolute;
    left: 0;
    top: 0;
}

.info {
    flex: 0.7;
    display: flex;
    gap: 10px;
}

.info select {
    flex: 1;
}

.info input {
    padding: 5px;
    border-radius: 8px;
    border-color: var(--hows-point-color);
    border-width: 2px;
    font-size: 17px;
}

.info input:nth-child(2) {
    flex: 4;
}

.info input:last-child {
    flex: 1;
}

.writeWrap {
    flex: 7.3;
}