.container {
  width: 100%;
  height: auto;
}

.container .menus {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 600;
}
.container .menus > div {
  padding: 15px 20px 10px 20px;
  margin-top: 50px;
  border-bottom: 1.5px solid var(--hows-point-color);
  cursor: pointer;
  transition-duration: 0.2s;
}
.container .menus > div:hover {
  color: var(--hows-point-color);
}
.container .menus > div.active {
  color: var(--hows-point-color-dark);
}
.container .menus .updateInfo {
  margin-right: 50px;
}
.container .menus .updatePw {
  margin-left: 50px;
}

.container .body {
  height: auto;
}
