.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: 900;
    margin: 60px 0;
}

.subTitle {
    width: 80%;
    min-width: 800px;
    max-width: 1300px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid var(--hows-gray-400);
    padding: 5px;
    font-size: 20px;
}

.orderInfo {
    width: 80%;
    min-width: 800px;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.orderInfo > .items {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:  300px;
    border: 1px solid var(--hows-point-color);
    border-radius: 5px;
    margin-bottom: 30px;
    overflow-y: auto;
    padding: 5px;
}

.orderInfo > .items > .item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 95%;
    height: 100px;
    border: 1px solid var(--hows-gray-400);
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px;
}

.orderInfo > .items > .item > .itemImage {
    width: 100px;
    height: 80px;
}

.orderInfo > .items > .item > .itemImage > img {
    width: 100%;
    height: 100%;
}

.orderInfo > .items > .item > .itemInfo {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
}

.orderInfo > .items > .item > .itemInfo > p {
    font-size: 14px;
}

.orderInfo > .items > .item > .itemInfo .itemCount {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
}

.orderInfo > .shipping {
    width: 48%;
    height:  300px;
    border: 1px solid var(--hows-point-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.orderInfo > .shipping > .addressCheck {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.orderInfo > .shipping > .addressCheck > div > input {
    margin-right: 6px;
}

.orderInfo > .shipping > .addressCheck > div > label {
    margin-right: 16px;
}

.orderInfo > .shipping > .addressCheck > button {
    border: none;
    background-color: var(--hows-point-color);
    color: var(--hows-white);
    padding: 5px;
}

.orderInfo > .shipping > .address {
    width: 90%;
    margin: 5px 0;
}

.orderInfo > .shipping > .address > .postcode {
    display: flex;
    justify-content: start;
}

.orderInfo > .shipping > .address > .postcode > input {
    height: 25px;
    margin-right: 15px;
    border: none;
    border-bottom: 1px solid var(--hows-gray-300);
}

.orderInfo > .shipping > .address > .postcode > button {
    border: none;
    background-color: var(--hows-point-color);
    border-radius: 5px;
    color: var(--hows-white);
    padding: 6px;
}

.orderInfo > .shipping > .address > input {
    width: 100%;
    height: 25px;
    border: none;
    border-bottom: 1px solid var(--hows-gray-300);
}

.orderInfo > .shipping > .member {
    width: 90%;
    margin-top: 15px;
}
.orderInfo > .shipping > .member > label {
    margin-right: 5px;
}
.orderInfo > .shipping > .member > input {
    width: 150px;
    height: 25px;
    border: none;
    border-bottom: 1px solid var(--hows-gray-300);
    padding: 2px;
}

.paymentInfo {
    width: 80%;
    min-width: 800px;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.paymentInfo > .paymentWay {
    width: 48%;
    display: flex;
    flex-direction: column;
    height:  300px;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 5px;
}

.paymentInfo > .paymentWay > .payment {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px 0;
}

.paymentInfo > .paymentWay > .payment > p {
    font-size: 18px;
    font-weight: 800;
}

.paymentInfo > .paymentWay > .payment > select {
    margin: 10px 0;
    width: 350px;
    font-size: 16px;
    font-weight: 500;
}

.paymentInfo > .paymentWay > .payment > div {
    margin: 10px 0;
}

.paymentInfo > .paymentWay > .payment > div > input {
    width: 90px;
    height: 25px;
    margin-right: 5px;
    border: none;
    border-bottom: 1px solid var(--hows-gray-400);
    text-align: right;
    font-weight: 700;
}

.paymentInfo > .paymentWay > .payment > div > span {
    font-size: 16px;
    margin-right: 20px;
}

.paymentInfo > .paymentWay > .payment > div > button {
    width: 110px;
    height: 40px;
    margin-right:10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: var(--hows-white);
    border-radius: 8px;
    border: 2px solid var(--hows-point-color);
    color: var(--hows-point-color-darker);
    font-weight: 700;
}

.paymentInfo > .paymentPrice {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:  300px;
    border: 1px solid var(--hows-point-color);
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 5px;
}

.paymentInfo > .paymentPrice > .priceInfo {
    width: 90%;
    display: flex;
    justify-content: end;
}

.paymentInfo > .paymentPrice > .priceInfo > div {
    text-align: right;
}

.paymentInfo > .paymentPrice > .priceInfo > div > p {
    margin: 6px;
    padding: 2px;
}

.paymentInfo > .paymentPrice > .priceInfo > div > p:nth-child(5) {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}

.paymentInfo > .paymentPrice > .consent {
    width: 90%;
    padding: 2px;
    margin-top: 3px;
    display: flex;
    align-items: center;
}

.paymentInfo > .paymentPrice > .consent > label {
    color: var(--hows-gray-500);
    margin-left: 5px;
}

.paymentInfo > .paymentPrice > .consent > label > a {
    font-weight: 600;
}

.paymentInfo > .paymentPrice > button {
    width: 90%;
    height: 45px;
    padding: 2px;
    margin: 8px;
    border: none;
    background-color: var(--hows-point-color);
    border-radius: 5px;
    color: var(--hows-white);
    font-size: 18px;
    font-weight: 900;
}

.modalBox {
    width: 450px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}