.categoryWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.categoryTit {
    font-size: 20px;
    font-weight: 700;
}

.categoryCont {
    display: flex;
    justify-content: space-evenly;
    /* 요소들을 가로로 배치하고 간격을 줌 */
    align-items: center;
    /* 아이템을 수직으로 가운데 정렬 */
    margin-bottom: 30px;
}

.categoryItem {
    text-align: center;
    /* 텍스트를 가운데 정렬 */
    margin: 0 10px;
    /* 아이템 간의 간격 */
}

.categoryImage {
    width: 80px;
    /* 이미지 너비 */
    height: 80px;
    /* 이미지 높이 */
    border-radius: 50%;
    /* 원형 이미지로 만듦 */
    object-fit: cover;
    /* 이미지 비율을 유지하며 크기 조정 */
    margin-bottom: 5px;
}

.categoryItem p {
    font-size: 14px;
    color: #333;
}

/* 1100px 이하일 때 3개 */
@media screen and (max-width: 1100px) {
    .contentCon {
        width: calc((100% / 3) - 20px);
    }
}

/* 태블릿 크기(768px) 이하일 때 2개 */
@media screen and (max-width: 768px) {
    .contentCon {
        width: calc((100% / 2) - 15px);
    }
}

@media screen and (max-width: 576px) {
    .container {
        padding: 40px 20px;
    }
}