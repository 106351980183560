.container {
  width: 100%;
  height: 100%;
  /* background-color: aliceblue; */
}

.container .countProducts {
  padding: 5px 0 10px 15px;
  font-size: 20px;
}

.container .countProducts > span:nth-child(1) {
  font-weight: 600;
  margin-right: 5px;
}

.container .item {
  display: flex;
  align-items: center;
  gap: 10px;
  /* width: 95%; */
  height: 160px;
  border: 1px solid var(--hows-gray-200);
  border-radius: 5px;
  margin: 15px 20px 25px 20px;
  padding: 15px 30px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.container .item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.container .item > .itemImage {
  width: 160px;
  height: 140px;
  border: 1px solid black;
}

.container .item > .itemImage > img {
  width: 100%;
  height: 100%;
}

.container .item > .itemInfo {
  width: 75%;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px;
}

.container .item > .itemInfo > p {
  font-size: 24px;
  font-weight: 600;
  margin: 0 20px;
}

.container .item > .itemInfo .itemCount {
  display: flex;
  flex-direction: column;
  margin: 0px 18px;
  justify-content: space-between;
  gap: 5px;
  font-size: 20px;
}
/* .container .item > .itemInfo .itemCount > span:nth-child(2) {
  font-size: 24px;
  font-weight: 600;
} */
