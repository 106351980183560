.container{
    max-width: 1300px;
    padding: 20px 0;
    width: 100%;
    margin: 0 auto;
}

.contents {
    display: flex;
    flex-direction: row; 
    height: 100%;
}

/* 목록 */
.side {
    height: 100%; flex: 2; min-width: 250px;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #fbecddb9;

    position: sticky;
    top: 120px; /* 스크롤 시 상단에 고정 */
    font-size: 18px;
    color: var(--hows-gray-800);
    letter-spacing: 1px;
}

.list > li{
    height: 80px; 
    display: flex;justify-content: start;align-items: center;
    padding-left: 30px;
    transition: all ease 0.1s;
    font-weight: bold;
    opacity: 1;
}
.list > li:hover{
    opacity: 0.4;
}

.imagesBox {
    width: 45px; height: 45px;
    padding: 0 10px;
}
/* 이미지 */
.imagesBox .image {
    object-fit: contain; 
    width: 100%; 
    height: 100%;
}



/* 상품 */
.content {
    height: 100%; 
    flex: 8; 
    min-width: 1000px;
    margin-left: 50px;
}

/* 상품 - 옵션 */
.content > div:first-child {
    position: relative;
    height: 100px;
    padding: 30px 15px 0 15px;
    display: flex; justify-content: space-between;
}
/* 가격 */


/* 인기순 */
.option {
    height: 50px;
    display: flex; 
    justify-content: space-between; 
    align-items: last baseline;
}
/* 상품 수 */
.length{
    font-weight: bold; 
    letter-spacing: 1px;
    font-size: 16px;
}
.content > div:first-child > .option > div select{
    background-color: #f5f5f5;
    border: none; 
    height: 50px; 
    border-radius: 8px;
    width: 100px; 
}

.content > div:last-child {
    display: flex; flex-wrap: wrap;
    padding: 20px 0px;
}
.item {
    width: 230px;
    height: 400px;
    margin: 10px;
    cursor: pointer;
}

.item:hover .img > img {
    transform: scale(1.1);
    opacity: 0.8;
}

/* 상품 이미지 */
.img {
    height: 300px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    transition: all 0.3s ease;
}
.img > img {
    object-fit: cover; 
    width: 100%;height: 100%; 
    transition: all 0.3s ease;
}

/* 상품 설명 */
.title {
    border:1px solid var(--hows-gray-200);
    border-radius: 0 0 8px 8px;
    height: 100px;
}

/* 상품 설명 - 상품명 */
.title > div{
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; text-align: center;
}

/* 상품 설명 - 상품 가격 */
.title > div:last-child{
    /* border:1px solid #000; */
    font-weight: bold;
}


/* 무한스크롤 정렬 */
.itemBox{display: flex;}


/* 옵션 */
.sortBox {
    display: flex;
    gap: 10px;
}
.sortBox select {
    width: 300px;
}

.selectedOptions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
}

.selectedOption {
    background-color: var(--hows-point-color-dark);
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    color: var(--hows-white);
}

.selectedOption button {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    color: var(--hows-white);
    font-weight: bold;
}