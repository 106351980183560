.communityContainer {
    flex: 9 1;
}

.headerSection {
    margin-bottom: 5px;
}

/* 검색창! */
.searchSection {
    display: flex;
    justify-content: flex-end;
}

.searchSection select,
.searchSection input {
    padding: 5px;
    margin-right: 5px;
}

.searchSection button {
    padding: 5px 10px;
}

/* 조회 하는 영역 */
.communitylist {
    font-size: 17px;
    height: 85%;
}

.communityHeader {
    display: flex;
    background-color: #555;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #ddd;
    color: var(--hows-white);
    height: 35px;
    justify-content: center;
    align-items: center;
}

.headerItem {
    flex: 1;
    text-align: center;
}

.communityRow {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    /* cursor: pointer; */
    height: 35px;
}

.communityItem {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deletebtn {
    padding: 10px 20px;
    background-color: #555;
    color: var(--hows-white);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.span{
    cursor: pointer;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* 신고 모달창 */
.reportModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background-color: var(--hows-white);
    padding: 20px;
    border-radius: 10px;
    width: 25%;
    text-align: center;
    border: 2px solid var(--hows-point-color);
}

.modalContent h3 {
    margin-bottom: 15px;
}

.reportTable {
    display: table;
    width: 100%;
    margin-bottom: 15px;
    table-layout: fixed;
    border: 2px solid var(--hows-point-color);
}

.tableHeader, .tableRow {
    display: table-row;
    text-align: center;
}

.tableHeader div, .tableRow div {
    display: table-cell;
    padding: 10px;
    width: 33%;
}

.tableHeader {
    font-weight: bold;
    background-color: #555;
    color: var(--hows-white);
}

.reportcount {
    cursor: pointer;
    background-color: var(--hows-red-dark);
    color: var(--hows-white);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.btn {
    background-color: #555;
    color: var(--hows-white);
    cursor: pointer;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    font-weight: bold;
}

/* 페이지네비 */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 88%;
    left: 50%;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination i {
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.empty{
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 40%;
}

/*게시물 상세*/
.detailmodalContent {
    background-color: var(--hows-white);
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    height: 70%;
    text-align: center;
    border: 2px solid var(--hows-point-color);
}

.authorInfo {
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: relative;
    left: 115px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid var(--hows-point-color);
}

.nickname {
    font-size: 18px;
}

.boardContent {
    margin-top: 15px;
    font-size: 16px;
}

.boardImage {
    max-width: 100%; 
    max-height: 400px; 
    object-fit: contain; 
    border-radius: 10px; 
    position: relative;
    top: 10px;
}

.close{
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative;
    top: 35px; */
    position: fixed;
    top: 82%;
    left: 935px;
}







