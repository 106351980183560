/* div {
  border: 1px solid black;
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.container .findPwBox,
.isVerify,
.changePwBox {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 500px;
  text-align: center;
}
.container .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 40px 10px;
  cursor: pointer;
}

.container .logo .logoBox {
  width: 260px;
  overflow: hidden;
  object-fit: cover;
}

.container .logo .logoBox > img {
  width: 100%;
}

.container .logo .title {
  display: flex;
  justify-content: center;
  font-size: 65px;
  padding-top: 45px;
  color: var(--hows-point-color);
}

.container input {
  height: 35px;
  border: 1px solid var(--hows-gray-200);
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 8px;
}
.container input:focus {
  /* 테두리 색상 */
  outline: 1px solid var(--hows-gray-200);
}

.container .findPwBox .errorText {
  color: var(--hows-red-dark);
  margin-bottom: 10px;
}

.container button {
  height: 50px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition-duration: 0.4s;
}

.container .btn {
  margin-top: 10px;
  background-color: var(--hows-point-color-dark);
  color: var(--hows-white);
}
.container .btn:hover {
  background-color: var(--hows-point-color-darker);
}

.container .backBtn {
  margin-top: 5px;
  background-color: var(--hows-gray-200);
  color: var(--hows-white);
}
.container .backBtn:hover {
  background-color: var(--hows-gray-300);
}

/* subTitle */
.container .subTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--hows-point-color-darkest);
  margin-top: -20px;
  margin-bottom: 30px;
}
