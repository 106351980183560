/* div {
  border: 1px solid black;
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px 0 170px;
  /* height: 100%; */
  /* background-color: azure; */
}

.container .updatePw {
  display: flex;
  flex-direction: column;
  width: 430px;
  height: auto;
  text-align: center;
}

.container .updatePw .currentPw,
.newPw,
.newPw2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.container .updatePw .currentPw {
  margin-bottom: 0px;
}

/* 비밀번호 일치, 불일치 확인 */
.container .updatePw .currentPw > div,
.newPw > div,
.newPw2 > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .updatePw .status > i {
  color: var(--hows-blue-dark);
  font-size: 30px;
}
.container .updatePw .status .discord {
  color: var(--hows-red-dark);
  font-size: 24px;
  font-weight: 600;
}

/* title */
.container .updatePw .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 3px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3px;
}

/* input  */
.container .updatePw input {
  height: 25px;
  border: 1px solid var(--hows-gray-200);
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  margin-top: 8px;
}
.container .updatePw input:focus {
  /* 테두리 색상 */
  outline: 1px solid var(--hows-gray-200);
}

/* 회원가입 버튼 */
.container .updatePw .okBtn,
.isCurrentPwBtn {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  height: auto;
}
.container .updatePw .okBtn > button,
.isCurrentPwBtn > button {
  width: 48%;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  background-color: var(--hows-point-color-dark);
  color: var(--hows-white);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition-duration: 0.4s;
  margin-top: 15px;
}

.container .updatePw .okBtn > button:hover {
  background-color: var(--hows-point-color-darker);
}

/* 비밀번호 확인 버튼 */
.isCurrentPwBtn {
  margin-bottom: 50px;
  transition-duration: 0.4s;
}
.isCurrentPwBtn > button:hover {
  background-color: var(--hows-point-color-darker);
}
