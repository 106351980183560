.profileSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileInfo {
    display: flex;
    align-items: center;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.nickname {
    font-weight: bold;
}

.time {
    color: gray;
    margin-left: 10px;
}

.actionButtons button {
    margin-left: 10px;
}