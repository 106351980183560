.scrollToTop {
    position: fixed;
    bottom: 150px;
    right: 40px;
    z-index: 1000;
}

.scrollBtn {
    background-color: var(--hows-point-color-light);
    /* 버튼 배경 색상 */
    border: none;
    border-radius: 50%;
    color: white;
    padding: 15px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    position: relative;
}

.scrollBtn:hover {
    background-color: var(--hows-point-color);
    /* 마우스 올렸을 때 색상 */
}

.scrollBtn::before {
    content: "";
    display: block;
    background: url(../../assets/images/scrolltop.png) center no-repeat;
    width: 24px;
    height: 24px;
}

.scrollBtn:focus {
    outline: none;
    /* 클릭 시 outline 제거 */
}

/* 모바일 크기(576px) 이하일 때 1개 */
@media screen and (max-width: 576px) {
    .scrollToTop {
        position: fixed;
        bottom: 100px;
        right: 10px;
        z-index: 1000;
    }

    .scrollBtn::before {
        content: "";
        display: block;
        background: url(../../assets/images/scrolltop.png) center no-repeat;
        width: 14px;
        height: 14px;
        background-size: 24px;
    }
}