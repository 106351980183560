/* ===== 상품 리뷰 ===== */
.container {
    border: 1px solid #00000029;
    border-radius: 8px;
    padding: 50px;
}

/* 리뷰 / 리뷰쓰기 */
.reviewsHeader {
    display: flex; justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}

.modalBox{
    /* margin: 5%; */
    width: 500px;
    padding: 30px;
    font-size: 16px; 
    font-weight: bold;
}

.modalBox > h2 {
    margin: 20px 0;
    font-size: 24px; 
}

.modalBox > h2:first-child{text-align: center;}
/* .modalBox > div:first-child span {border: 1px solid red;} */
.reviewModal {
    display: flex;flex-direction: column;
}
.reviewModal > button {
    font-weight: bold;
    font-size: 24px;
    background-color: var(--hows-point-color);
    border: none;
    height: 50px;
    border-radius: 0 0 8px 8px;
}
.reviewContent {
    height: 100px;
    border: none;
    padding: 20px;
    background-color: #f5f5f56e;
}

/* 사진 첨부하기 */
.filesBox {
    margin-top: 15px;
    padding: 6px 0;
    background-color: #f5f5f5;
    width: 100%; /* 부모 요소에 너비를 지정 */
}
.fileBtn {
    display: inline-block; 
    width: 100%;
    font-size: 16px;
    color: var(--hows-gray-300);
    padding: 6px 25px;
    cursor: pointer;
    text-align: center; 
    box-sizing: border-box; /* 패딩 포함 너비 계산 */
}
.fileBtn > div {
    display: flex;justify-content: center;align-items: center;
}
.files {
    display: none; /* input 숨김 */
}
.filesTitle {
    font-size: 14px; 
    margin: 5px 0; 
    color: var(--hows-gray-200); 
    letter-spacing: 1px; 
    margin-bottom: 10px;
}

/* 이미지 미리보기 */
.previewContainer {
    display: flex;            
    gap: 10px;                
    flex-wrap: wrap; /* 이미지가 많을 경우 다음 줄로 넘어가게 함 */
    margin-bottom: 10px;         
    
}
.previewImage {
    width: 100px;              
    height: 100px;             
    object-fit: cover;        
    border-radius: 5px;       
    border: 1px solid #ddd;    
}
/* 이미지 취소 */
.previewImageContainer {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.deleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color:var(--hows-point-color);
    border: 1px solid #ddd;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 12px;
}


/* 별점 */
.reviewsStarRating {
    margin-bottom: 30px;
    border: 1px solid var(--hows-gray-200);
    margin: 30px 0; padding: 30px;
    display: flex;
}
.reviewsStarRating > div {
    flex: 1;
    letter-spacing: 1px; font-weight: bold;
}
.reviewsStarRating > div{
    display: flex; justify-content: center; 
    align-items: center;
}
.reviewsStarRating > div:first-child span{font-size: 14px;}
.reviewsStarRating > div:last-child ul{}
.reviewsStarRating > div:last-child ul li{margin: 5px 0;}



/* ===== 리뷰 ===== */
/* .reviewsMain {border: 1px solid var(--hows-gray-300);} */
/* 리뷰 - 베스트/최신 */
.option{
    display: flex; 
    gap: 20px; 
    height: 40px; line-height: 40px;
    font-weight: bold;
}
.comboBox {
    width: 200px; 
    padding: 10px;
    font-size: 16px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    background-color: #f9f9f9; 
    cursor: pointer; 
    transition: all 0.3s ease;
}

.comboBox:focus {
    outline: none; /* 기본 포커스 제거 */
    border-color: var(--hows-point-color); /* 포커스 시 테두리 색상 */
}
/* 리뷰 내용 */
/* .reviewBox{border: 1px solid red;} */
.reviewBox button {cursor: pointer;}
.reviewBox > div {margin: 30px 0;}
.reviewBox > div > div:first-child{
    display: flex; flex-direction: row;
}
.reviewBox > div > div:first-child > div {flex: 1;}
/* 내 이미지/닉네임/별점 */
.reviewBox > div > div:first-child > div:first-child {
    display: flex;
    gap: 20px; 
    margin-bottom: 10px;
}
.reviewBox > div > div:first-child > div:first-child > div:first-child{
    width: 50px;height: 50px; 
    overflow: hidden; 
    border-radius: 50%;cursor: pointer;
}
.reviewBox > div > div:first-child > div:first-child > div:first-child img{
    width: 100%;height: 100%;
}
.reviewBox > div > div:first-child > div:first-child > div:last-child >div {
    padding-bottom: 10px;
}
/* 닉네임 */
.reviewBox > div > div:first-child > div:first-child > div:last-child >div:first-child{
    font-weight: bold; 
    letter-spacing: 1px;
}
/* 별점 */
.reviewBox > div > div:first-child > div:first-child > div:last-child >div:last-child{
    padding-bottom: 0;
}


/* 버튼 */
.reviewBox > div > div:first-child > div:last-child {
    display: flex; 
    justify-content: right; 
    align-items: center;
    gap: 10px;
}
.reviewBox > div > div:first-child > div:last-child > button {
    border: none;
    background-color: #f0f0f0; 
    padding: 10px 20px; /* 버튼의 내부 여백 */
    border-radius: 8px; 
    cursor: pointer;
    transition: background-color 0.1ms ease, color 0.3s ease; /* 부드러운 색상 전환 */
}
.reviewBox > div > div:first-child > div:last-child > button:hover {
    background-color:var(--hows-point-color); 
}

.reviewBox > div > div:last-child{
    display: flex;gap: 20px; 
    
}
.reviewBox > div > div:last-child > div:first-child {
    width: 500px; height: 200px;
    border: 1px solid #f0f0f0;
    border-radius: 8px; 
    display: flex;
    justify-content: flex-start;
}
.reviewBox > div > div:last-child > div:first-child > div{
    width: 200px; height: 200px;
}
.reviewBox > div > div:last-child > div:first-child > div img{
    width: 100%;height: 100%; 
    object-fit: cover;
}
/* 날짜/리뷰내용 */
.reviewBox > div > div:last-child > div:last-child {
    display: flex; flex-direction: column; flex-grow: 1;
    padding: 20px;box-sizing: border-box; 
    width: 500px; height: 200px; 
    background-color: #f0f0f078;
    border-radius: 8px; 
}
/* 날짜 */
.reviewBox > div > div:last-child > div:last-child > div:first-child {
    flex: 2;
    text-align: right; 
    font-weight: bold; 
    font-size: 14px;
    letter-spacing: 1px;
}
/* 리뷰내용 */
.reviewBox > div > div:last-child > div:last-child > div:last-child {
    flex: 8; 
    overflow-y: auto; 
    padding: 20px;
}



/* 로딩중 */
.loading {
    font-size: 18px;
    font-weight: bold;
    color: var(--hows-point-color);
    text-align: center;
    padding: 20px;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
